import React from "react";
import { Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Hidden from "@material-ui/core/Hidden";

import { OutboundLink } from "gatsby-plugin-google-analytics";

import iPadVid from "../assets/ipad_website.mp4";
import planCaptureVid from "../assets/apt_vis_640.mp4";
import Stopwatch from "../images/stopwatch_with_marks.svg";
import LeadsIncrease from "../images/leads_graph.svg";

import Layout from "../components/layout";
import CallToAction from "../components/cta";
import SEO from "../components/seo";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  pictureText: {
    padding: "1em",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "@media(orientation: portrait)": {
      maxWidth: "70vh",
      margin: "0px auto",
      alignSelf: "center",
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  article: {
    height: "100%",
    "@media(orientation: portrait)": {
      width: "100%",
    },
  },
  image: {
    position: "relative",
    flexGrow: 50,
    minWidth: "20%",
    maxWidth: "100%",
    marginRight: "2em",
    marginLeft: "1em",
    flexBasis: 0,
    "@media(orientation: portrait)": {
      marginRight: "0em",
      marginLeft: "0em",
      marginTop: "0em",
      marginBottom: "0em",
      width: "100%",
      flexBasis: 0,
    },
  },
  spacer: {
    flexGrow: 10,
    flexShrink: 1,
    flexBasis: 0,
  },
  text: {
    flexGrow: 32,
    flexBasis: 0,
    "@media(orientation: portrait)": {
      flexBasis: "auto",
    },
  },
});

const FullscreenPage = ({
  backgroundColor,
  children,
  first,
  pageRef,
  ...otherProps
}) => {
  const height = first ? "calc(100vh - var(--headerHeight))" : "100vh";
  const classes = useStyles();
  return (
    <div
      style={{
        height,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        scrollSnapAlign: first ? "end" : "start",
        overflow: "hidden",
      }}
      {...otherProps}
      ref={pageRef}
    >
      {!first && (
        <div
          style={{
            height: `var(--headerHeight)`,
            flexShrink: 0,
            width: "100%",
          }}
        />
      )}
      {backgroundColor && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor,
            position: "absolute",
            zIndex: -1,
            left: 0,
          }}
        />
      )}
      <article className={classes.article}>{children}</article>
    </div>
  );
};

function TwoSidesTextFullPage({
  header,
  pageRef,
  sideElement,
  backgroundColor,
  children,
  first,
}) {
  const classes = useStyles();
  return (
    <FullscreenPage
      first={first}
      backgroundColor={backgroundColor}
      pageRef={pageRef}
    >
      <div className={classes.pictureText}>
        <div className={classes.image}>{sideElement}</div>
        <div className={classes.spacer} />
        <div className={classes.text}>
          <h1 style={{ textDecoration: "underline" }}>{header}</h1>
          {children}
        </div>
      </div>
    </FullscreenPage>
  );
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      ipad_frame: file(relativePath: { eq: "ipad_frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      floor_plans_stack: file(relativePath: { eq: "floor_plans.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coach_space: file(
        relativePath: { eq: "pexels-the-coach-space-2977565.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const learnMoreTargetRef = React.useRef(null);

  console.log({ data });
  return (
    <Layout fullscreen removeCTA>
      <SEO 
      title="Home" 
      description= "Learn how PanoRoom can change your business using cutting-edge Augmented Reality technology."
      // image={data.floor_plans_stack.childImageSharp}
      />
      <TwoSidesTextFullPage
        header="Earn extra on floor plans"
        sideElement={
          <>
            <video
              controls={false}
              muted
              autoPlay
              playsInline
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                position: "absolute",
                transform: "scale(0.935)",
              }}
            >
              <source src={iPadVid} type="video/mp4" />
            </video>
            <Img
              {...data.ipad_frame.childImageSharp}
              imgStyle={{ objectFit: "contain" }}
              style={{ height: "100%" }}
            />
          </>
        }
        backgroundColor="#FFF"
        first
      >
        <p>Lightning-fast floor scans for real estate photographers & agents</p>
        <div>
          <StartButton />
        </div>
        <p></p>
        <div>
          <Button
            variant="contained"
            color="black"
            onClick={() => {
              learnMoreTargetRef.current.scrollIntoView({ behavior: "smooth" });
            }}
            style={{
              backgroundColor: "#243B55",
              color: "white",
              borderRadius: "1em",
            }}
          >
            Learn more ↓
          </Button>
        </div>
      </TwoSidesTextFullPage>

      <TwoSidesTextFullPage
        header="A better way to create floor plans"
        sideElement={
          <>
        <div style = {{display: "flex",
              justifyContent: "center"}}>
          <video
            controls={false}
            muted
            autoPlay
            playsInline
            loop
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "85vw", 
              margin: "0px auto",
              maxHeight: "100vw",
              flexGrow: 32,
              flexBasis: 1
            }}
          >
            <source src={planCaptureVid} type="video/mp4" />
          </video>
        </div>
          </>
        }
        pageRef={learnMoreTargetRef}
        backgroundColor="#DDD"
      >
        <div>
          <ul>
            <li>Use your iOS device to scan entire apartments in minutes</li>
            <li>
              No post-production: an accurate plan is immediately ready to send
              to the client
            </li>
          </ul>

          <div>
            <Link to="/2020-12-01-panoroom-unique/">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#243B55",
                  color: "white",
                  borderRadius: "1em",
                  fontWeight: "bold",
                }}
              >
                See what makes PanoRoom unique →
              </Button>
            </Link>
          </div>
        </div>
      </TwoSidesTextFullPage>

      <TwoSidesTextFullPage
        header="Advantages for Real Estate Professionals"
        sideElement={
          <div className="advantages_pictures">
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <img
                src={Stopwatch}
                style={{ maxWidth: "40%", transform: "scale(0.7)" }}
              />
              <h3
                style={{
                  display: "flex",
                  flexGrow: 32,
                  maxWidth: "40%",
                  fontWeight: "bold",
                }}
              >
                +500% faster than a typical floor capture
              </h3>
            </div>
            <Hidden smDown>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <img src={LeadsIncrease} style={{ maxWidth: "40%" }} />
                <h3
                  style={{
                    display: "flex",
                    flexGrow: 32,
                    maxWidth: "40%",
                    fontWeight: "bold",
                  }}
                >
                  <OutboundLink href="https://www.rightmove.co.uk/news/articles/property-news/maximise-chances-finding-buyer-floorplan/">
                    +10-30% More leads (source)
                  </OutboundLink>
                </h3>
              </div>
            </Hidden>
          </div>
        }
        backgroundColor="#FFF"
      >
        <div>
          <ul>
            <li>Offer extra service with little effort</li>
            <li>Stand out from the competition, earn extra income</li>
            <li>
              Get more leads per listing, sell more estates in the same time
            </li>
          </ul>

          <div>
            <Link to="/2020-12-01-realtors-advantages/">
              <Button
                variant="contained"
                style={{ backgroundColor: "#243B55", color: "white" }}
              >
                See how on-the-go floor plans can change your business →
              </Button>
            </Link>
          </div>
        </div>
      </TwoSidesTextFullPage>
      <TwoSidesTextFullPage
        header="Try it for yourself, risk-free"
        sideElement={
          <Img
            {...data.floor_plans_stack.childImageSharp}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "100%", maxWidth: "65vw", margin: "0px auto" }}
          />
        }
        backgroundColor="#DDD"
      >
        <div>
          <ul>
            <li>No additional hardware or upfront costs</li>
            <li>
              High-quality floor plans with 97% accuracy, dimensions, room areas
              and solar orientation (PDF/PNG)
            </li>
            <li>Unlimited floor plans for $9.49/month</li>
          </ul>
          <div>
            <StartButton />
          </div>
          <p></p>
          <div>
            <a href="/assets/panoroom-sample-plans.zip" download>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#243B55",
                  color: "white",
                  borderRadius: "1em",
                }}
              >
                Download sample plans →
              </Button>
            </a>
          </div>
        </div>
      </TwoSidesTextFullPage>

      <TwoSidesTextFullPage
        backgroundColor="#FFF"
        sideElement={<Img {...data.coach_space.childImageSharp} />}
      >
        <CallToAction removeBackgroundColor />
      </TwoSidesTextFullPage>
    </Layout>
  );
};

function StartButton() {
  return (
    <Link to="/download">
      <Button
        variant="contained"
        color="black"
        style={{
          backgroundColor: "#243B55",
          color: "white",
          borderRadius: "1em",
          fontWeight: "bold",
        }}
      >
        Start for free →
      </Button>
    </Link>
  );
}

export default IndexPage;
